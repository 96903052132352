import React, { useState } from "react";
import GetStarted from "../components/setupFarm/GetStarted";
import FarmSetupInfo from "../components/setupFarm/FarmSetupInfo";
import FarmFields from "../components/setupFarm/FarmFields";
import SuccesfulImport from "../components/setupFarm/SuccessfulImport";
import AddNewField from "../components/setupFarm/AddNewField";
import styles from "../../src/styles/routes/FarmSetup.module.scss";
import farmSetupBg from "../assets/farmSetupBg.png";
import logo from "../assets/logo.svg";
import useIsMobile from "../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import Modal from "../components/modal/Modal";
import successIcon from "../assets/icons/successful.svg";
import { FormButton } from "../components/form/Form";

const FarmSetup: React.FC = () => {
  const [page, setPage] = useState("GetStarted");
  const [isSuccessCreateFieldModalOpen, setIsSuccessCreateFieldModalOpen] =
    useState(false);
  const [newFieldName, setNewFieldName] = useState("");

  const pages = [
    "GetStarted",
    "FarmSetupInfo",
    "FarmFields",
    "AddNewField",
    "SuccessfulImport",
  ];

  const changePage = ({
    page,
    isForward,
    goToIndex,
  }: {
    page?: string;
    isForward?: boolean;
    goToIndex?: string;
  }) => {
    if (goToIndex) return setPage(pages[parseInt(goToIndex, 10)]);

    const index = pages.findIndex((p) => {
      return p === page;
    });

    if (isForward) {
      setPage(pages[index + 1]);
    } else {
      setPage(pages[index - 1]);
    }
  };

  const handleSuccessCreateFieldOpen = (fieldName: string) => {
    setNewFieldName(fieldName);
    setIsSuccessCreateFieldModalOpen(true);
  };

  const handleSuccessModalClose = () => {
    setIsSuccessCreateFieldModalOpen(false);
    setPage("FarmFields");
  };

  const isMobile = useIsMobile();
  const pagesHideOnMobile = [
    "FarmSetupInfo",
    "FarmFields",
    "SuccessfulImport",
    "AddNewField",
  ];
  const shouldHideBackground = isMobile && pagesHideOnMobile.includes(page);
  const shouldShowSidebar = !(isMobile && pagesHideOnMobile.includes(page));

  const navigate = useNavigate();
  const mobileModalStyle:React.CSSProperties={
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    maxWidth: "107vw",
    height: "100%",
    maxHeight: "107vh",
    bottom: "0",
    borderRadius: 0,
    overflow:"hidden",
  }

  return (
    <>
      <main
        className="main"
        style={{
          margin: "-1.6rem",
          backgroundImage: shouldHideBackground
            ? "none"
            : `url(${farmSetupBg})`,
          backgroundRepeat: shouldHideBackground ? "initial" : "no-repeat",
          backgroundSize: shouldHideBackground ? "auto" : "cover",
          height: "100vh",
        }}
      >
        <section className={styles["main__section"]}>
          {shouldShowSidebar && (
            <div className={styles["main__section-sidebar"]}>
              <img src={logo} alt="Digital Farmer" />
              <h1>Digital Farmer</h1>
              <p>Helping You manage Your Farm easily wherever you are.</p>
            </div>
          )}
          {page === "GetStarted" && (
            <GetStarted page={page} changePage={changePage} />
          )}
          {page === "FarmSetupInfo" && (
            <FarmSetupInfo page={page} changePage={changePage} />
          )}
          {page === "FarmFields" && (
            <FarmFields page={page} changePage={changePage} />
          )}
          {page === "SuccessfulImport" && (
            <SuccesfulImport page={page} changePage={changePage} />
          )}
          {page === "AddNewField" && (
            <AddNewField
              page={page}
              changePage={changePage}
              closeModal={() => navigate("/")}
              headingTitle="New Field"
              headingSubtitle="Add a new field to your Farm"
              headingSubtitleStyle={{ color: "rgba(13, 24, 20, 0.5)" }}
              onSuccess={handleSuccessCreateFieldOpen}
              
          />
          )}
        </section>
      </main>
      <Modal
        headingTitle=""
        isOpen={isSuccessCreateFieldModalOpen}
        showCloseButton={false}
        onClose={() => setIsSuccessCreateFieldModalOpen(false)}
        size={isMobile ? "lg" : "md"}
        customStyle={isMobile?mobileModalStyle: undefined}
      >
        <div className={styles["success-modal-content"]}>
          <img src={successIcon} alt="Success" />
          <h2 className={styles["success-modal__title"]}>Field Added!</h2>
          <p className={styles["success-modal__message"]}>
            You added a new field: <span>{newFieldName}</span>.
          </p>
          <FormButton children="Got it!" action={handleSuccessModalClose} />
        </div>
      </Modal>
    </>
  );
};

export default FarmSetup;
