import { ReactNode, CSSProperties, useState, useEffect } from "react";

import closeIcon from "../../assets/icons/close.svg";

import styles from "./Modal.module.scss";

export default function Modal({
  children,
  headingTitle,
  headingSubtitle,
  headingContentStyle,
  headingIconStyle,
  headingTitleStyle,
  headingIcon,
  size = "lg",
  isOpen,
  onClose,
  actionButtonTitle,
  actionButtonFn,
  customClassName,
  customStyle,
  showCloseButton = true,
}: {
  children?: ReactNode;
  headingTitle: string;
  headingContentStyle?: CSSProperties;
  headingSubtitle?: string;
  headingIcon?: string;
  headingIconStyle?: CSSProperties;
  size?: string;
  isOpen: boolean;
  onClose: () => void;
  actionButtonTitle?: string;
  actionButtonFn?: () => void;
  customClassName?: string;
  customStyle?: CSSProperties;
  showCloseButton?: boolean;
  headingTitleStyle?: CSSProperties;
}) {
  const [isMobile, setIsMobile] = useState(false);

  const getBreakPoint = (variable: string) => {
    return parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(variable),
      10
    );
  };

  const observer = new ResizeObserver(() => {
    const breakpoint = getBreakPoint("--breakpoint-sm");
    if (window.innerWidth <= breakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  useEffect(() => {
    observer.observe(document.body);
  }, []);

  if (!isOpen) return <></>;


  return (
    <>
      <div className={styles.overlay}>
        <div
          className={`${styles.modal} ${size ? styles["modal--" + size] : ""} ${
            customClassName ? customClassName : ""
          }`}
          style={customStyle}
        >
          <div className={`${styles.modal__heading}`}>
            {headingIcon && (
              <div
                className={styles.modal__heading__icon}
                style={headingIconStyle}
              >
                <img src={headingIcon} alt={headingTitle} />
              </div>
            )}
            <div
              className={styles.modal__heading__content}
              style={headingContentStyle}
            >
              <h2
                className={styles.modal__heading__title}
                style={headingTitleStyle}
              >
                {headingTitle}
              </h2>
              {headingSubtitle && (
                <p className={styles.modal__heading__subtitle}>
                  {headingSubtitle}
                </p>
              )}
            </div>
            <div className={styles.modal__heading__actions}>
              {!isMobile && actionButtonTitle && actionButtonFn && (
                <button
                  className={styles["modal__heading__action-button"]}
                  type="button"
                  onClick={actionButtonFn}
                >
                  {actionButtonTitle}
                </button>
              )}
              {showCloseButton && (
                <div className={styles.modal__heading__close}>
                  <button
                    className={styles.modal__heading__button}
                    onClick={onClose}
                    type="button"
                  >
                    <img src={closeIcon} alt="Close modal" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.modal__actionButton_mobile}>
            {isMobile && actionButtonTitle && actionButtonFn && (
              <button
                className={styles["modal__heading__action-button"]}
                type="button"
                onClick={actionButtonFn}
              >
                {actionButtonTitle}
              </button>
            )}
          </div>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
}
