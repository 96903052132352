import React, {
  ButtonHTMLAttributes,
  ChangeEvent,
  CSSProperties,
  ReactNode,
  useRef,
  useState,
  useCallback,
} from "react";

import styles from "../../styles/Form.module.scss";

export function Form({
  children,
  isInline = false,
  customClassName,
  customCSS,
  onSubmit,
}: {
  children: ReactNode;
  isInline?: boolean;
  customClassName?: string;
  customCSS?: CSSProperties;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}) {
  return (
    <form
      className={`${styles.form ? styles.form : ""} ${
        customClassName ? customClassName : ""
      } ${isInline ? styles["form--inline"] : ""}`}
      style={customCSS}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
}

export function FormGroup({
  children,
  isRow,
  customClassName,
  customCSS,
}: {
  children: ReactNode;
  isRow?: boolean;
  customClassName?: string;
  customCSS?: CSSProperties;
}) {
  return (
    <div
      className={`${styles.form__group} ${
        customClassName ? customClassName : ""
      } ${isRow ? styles["form__group-row"] : ""}`}
      style={customCSS}
    >
      {children}
    </div>
  );
}

export function FormInput({
  id,
  name,
  type = "text",
  label,
  placeholder,
  isInline,
  isRequired = false,
  customCSS,
  hasError = false,
  customClassName,
  value,
  onChange,
  inputRef,
}: {
  id: string;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  isInline?: boolean;
  isRequired?: boolean;
  customCSS?: CSSProperties;
  customClassName?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  hasError?: boolean;
}) {
  const ref = useRef<HTMLInputElement>(null);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (hasError && ref.current) {
        ref.current.classList.add(styles["form__input--error"]);
      } else if (ref.current) {
        ref.current.classList.remove(styles["form__input--error"]);
      }

      if (onChange) {
        onChange(event);
      }
    },
    [onChange, hasError]
  );

  return (
    <div
      className={`
        ${styles.form__input} 
        ${isInline ? styles["form__input--inline"] : ""}
        ${hasError ? styles["form__input--error"] : ""}
      `}
      style={customCSS}
    >
      {label && (
        <label className={styles.form__label} htmlFor={id}>
          {label}
        </label>
      )}
      <div
        className={type === "date" ? styles["form__input__date-wrapper"] : ""}
      >
        <input
          ref={inputRef}
          id={id}
          name={name}
          type={type}
          required={isRequired}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          className={customClassName}
        />
      </div>
    </div>
  );
}

export function FormTextarea({
  id,
  name,
  label,
  value,
  placeholder,
  isInline,
  isRequired = false,
  customCSS,
  hasError = false,
  customClassName,
  onChange,
}: {
  id: string;
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  isInline?: boolean;
  isRequired?: boolean;
  customCSS?: CSSProperties;
  customClassName?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  hasError?: boolean;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (hasError && textareaRef.current) {
        textareaRef.current.classList.add(styles["form__textarea--error"]);
      } else if (textareaRef.current) {
        textareaRef.current.classList.remove(styles["form__textarea--error"]);
      }

      if (onChange) {
        onChange(event);
      }
    },
    [onChange, hasError]
  );

  return (
    <div
      className={`
        ${styles.form__textarea} 
        ${isInline ? styles["form__textarea--inline"] : ""}
        ${hasError ? styles["form__textarea--error"] : ""}
      `}
      style={customCSS}
    >
      {label && (
        <label className={styles.form__label} htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        ref={textareaRef}
        id={id}
        name={name}
        required={isRequired}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        className={customClassName}
      >
        {value}
      </textarea>
    </div>
  );
}

export function FormSelect({
  id,
  name,
  label,
  icon,
  selectOptions,
  value,
  onChange,
  isInline,
  isRequired = false,
  customClassName,
  customCSS,
  hasError = false,
  inputRef,
  selectClassName,
}: {
  id: string;
  name: string;
  label: string;
  icon?: string;
  selectOptions: Array<string>;
  isInline?: boolean;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  isRequired?: boolean;
  customClassName?: string;
  customCSS?: CSSProperties;
  hasError?: boolean;
  inputRef?: React.Ref<HTMLSelectElement>;
  selectClassName?: string;
}) {
  const selectRef = useRef<HTMLSelectElement>(null);
  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (hasError && selectRef.current) {
        selectRef.current.classList.add(styles["form__select--error"]);
      } else if (selectRef.current) {
        selectRef.current.classList.remove(styles["form__select--error"]);
      }

      if (onChange) {
        onChange(event);
      }
    },
    [onChange, hasError]
  );

  return (
    <div
      className={`
        ${styles.form__select} 
        ${icon ? styles["form__select--with-icon"] : ""} 
        ${customClassName ? customClassName : ""}
        ${isInline ? styles["form__select--inline"] : ""}
        ${hasError ? styles["form__select--error"] : ""}
      `}
      style={customCSS}
    >
      <label className={styles.form__label} htmlFor={id}>
        {label}
      </label>
      <div>
        {icon && <img src={icon} alt={label} />}
        <select
          ref={inputRef}
          id={id}
          name={name}
          required={isRequired}
          value={value}
          onChange={handleSelectChange}
          className={selectClassName}
        >
          {selectOptions.map((optionValue, index) => (
            <option
              key={optionValue.replace(" ", "_") + index}
              value={optionValue}
            >
              {optionValue}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export function FormCheckButton({
  id,
  name,
  label,
  icon,
  isRequired = false,
  customCSS,
  customClassName,
  labelClassName,
  iconClassName,
  isChecked,
  onClick,
}: {
  id: string;
  name: string;
  label: string;
  icon?: string;
  isRequired?: boolean;
  customCSS?: CSSProperties;
  customClassName?: string;
  labelClassName?: string;
  iconClassName?: string;
  isChecked?: boolean;
  onClick?: () => void;
}) {
  return (
    <>
      <div
        className={`${styles["form__check-button"]} ${
          isChecked ? styles["checked"] : ""
        } ${customClassName}`}
        style={customCSS}
        onClick={onClick}
      >
        <input
          id={id}
          name={name}
          type="checkbox"
          required={isRequired}
          checked={isChecked}
        />
        <label htmlFor={id} className={labelClassName}>
          <img src={icon} alt={label} className={iconClassName} />
          {label}
        </label>
      </div>
    </>
  );
}

export function FormCheckboxDropdown({
  name,
  selectOptions,
  icon,
  customClassName,
  customCSS,
  onChange,
}: {
  name: string;
  selectOptions: Array<string>;
  icon?: string;
  customClassName?: string;
  customCSS?: CSSProperties;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  const [isActive, setIsActive] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;

    if (target.checked) {
      setSelectedItems((prev) => [...prev, target.value]);
    } else {
      setSelectedItems((prev) =>
        prev.filter((itemValue) => itemValue !== target.value)
      );
    }

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div
      className={`
        ${styles["form__checkbox-dropdown"]} 
        ${isActive ? styles["form__checkbox-dropdown--active"] : ""}
        ${customClassName ? customClassName : ""}
      `}
      style={customCSS}
    >
      <button
        className={styles["form__checkbox-dropdown__heading"]}
        type="button"
        onClick={() => setIsActive(!isActive)}
      >
        <span className={styles["form__checkbox-dropdown__title"]}>{name}</span>
        {selectedItems.length !== 0 && (
          <span className={styles["form__checkbox-dropdown__status"]}>
            {selectedItems.length}
          </span>
        )}
        {icon && <img src={icon} alt={name} />}
      </button>
      <ul className={styles["form__checkbox-dropdown__content"]}>
        <li className={styles["form__checkbox-dropdown__item"]}>
          {"Select " + name}
        </li>
        {selectOptions.map((value, index) => (
          <li
            key={value.replace(" ", "_") + index}
            className={styles["form__checkbox-dropdown__item"]}
          >
            <input
              id={value.toLocaleLowerCase().replace(" ", "-") + index}
              name={name.toLocaleLowerCase().replace(" ", "-")}
              value={value}
              type="checkbox"
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor={value.toLocaleLowerCase().replace(" ", "-") + index}
            >
              {value}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function FormButton({
  children,
  type = "submit",
  action,
  isTransparent,
  customCSS,
}: {
  children: React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  action?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isTransparent?: boolean;
  customCSS?: CSSProperties;
}) {
  return (
    <button
      className={`${styles.form__button} ${
        isTransparent ? styles["form__button--transparent"] : ""
      }`}
      style={customCSS}
      type={type}
      {...(action && { onClick: action })}
    >
      {children}
    </button>
  );
}
