import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/SectionHeading.module.scss";

import linkIcon from "../../assets/icons/seeAllArr.svg";

import { Trans } from "@lingui/react";
import filterIcon from "../../assets/icons/filterIcon.svg";
import useIsMobile from "../../hooks/useIsMobile";
import { LocaleContext } from "../../useContext/localeContext";

export default function SectionHeading({
  title,
  label,
  action,
  icon,
  iconBackground,
  filterAction,
  filterCount = 0,
  customClassName,
  showActionButton = true,
}: {
  title: string;
  label?: string;
  action?: () => void;
  icon?: string;
  iconBackground?: string;
  filterAction?: () => void;
  filterCount?: number;
  customClassName?: string;
  showActionButton?: boolean;
}) {
  const [isMobile, setIsMobile] = useState(false);

  const getBreakPoint = (variable: string) => {
    return parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(variable),
      10
    );
  };

  const observer = new ResizeObserver(() => {
    const breakpoint = getBreakPoint("--breakpoint-sm");
    if (window.innerWidth <= breakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  useEffect(() => {
    observer.observe(document.body);
  });

  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem('appLocale') || 'en-US';
    setLocale(currentLocale, 'section');
  }, [setLocale]);

  return (
    <>
      <div
        className={`${styles.heading} ${customClassName || ""}`}
        // {...(isMobile && action ? { onClick: action } : {})}
        onClick={isMobile && !showActionButton && action ? action : undefined}
      >
        <h2 className={styles.heading__title}>
          {icon && (
            <img
              className={styles.heading__icon}
              src={icon}
              alt={title}
              style={{
                background: iconBackground,
              }}
            />
          )}{" "}
          {title} {label && <span>{label}</span>}
        </h2>
        {filterAction && (
          <button className={styles.heading__filter} onClick={filterAction}>
            <span>Filter</span>
            {filterCount > 0 && (
              <span className={styles.heading__number}>{filterCount}</span>
            )}
            <img src={filterIcon} alt="Filter Icon" />
          </button>
        )}
        {showActionButton && action && (
          <button className={styles.heading__link} onClick={action}>
            <span><Trans id="sectionHeading.seeAll">See All</Trans></span>
            <img src={linkIcon} alt="See All" />
          </button>
        )}
      </div>
    </>
  );
}
