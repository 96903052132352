import { useContext, useEffect } from "react";
import { LocaleContext } from "../useContext/localeContext";
import { NavLink } from "react-router-dom";

import logo from "../assets/logo.png";
import homeIcon from "../assets/icons/heading/headingHome.svg";
import homeIconGreen from "../assets/icons/homeIconGreen.svg";
import profileIcon from "../assets/icons/profile.svg";
import profileIconGreen from "../assets/icons/profileIconGreen.svg";
import filesIcon from "../assets/icons/heading/headingTasks.svg";
import filesIconGreen from "../assets/icons/tasksIconGreen.svg";
import starIcon from "../assets/icons/star.svg";
import startIconGreen from "../assets/icons/starIconGreen.svg";

import styles from "../styles/Header.module.scss";

import { Trans } from "@lingui/react";

export default function Header() {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "header");
  }, [setLocale]);

  return (
    <>
      <header className={styles.header}>
        <nav className={styles.nav}>
          <NavLink className={styles.nav__logo} to="/">
            <img src={logo} alt="Farmers App" />
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/"
          >
            {({ isActive }) => (
              <>
                <img src={isActive ? homeIconGreen : homeIcon} alt="Home" />
                <span>
                  <Trans id="header.home">Home</Trans>
                </span>
              </>
            )}
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/tasks"
          >
            {({ isActive }) => (
              <>
                <img src={isActive ? filesIconGreen : filesIcon} alt="files" />
                <span>
                  <Trans id="header.tasks">Tasks</Trans>
                </span>
              </>
            )}
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/task-recommendations"
          >
            {({ isActive }) => (
              <>
                <img src={isActive ? startIconGreen : starIcon} alt="files" />
                <span>
                  <Trans id="header.tasks">Tasks</Trans>
                </span>
              </>
            )}
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/profile"
          >
            {({ isActive }) => (
              <>
                <img
                  src={isActive ? profileIconGreen : profileIcon}
                  alt="files"
                />
                <span>
                  <Trans id="header.tasks">Tasks</Trans>
                </span>
              </>
            )}
          </NavLink>
        </nav>
      </header>
    </>
  );
}
