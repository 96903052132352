import React, { useEffect, useState } from "react";
import {
  Form,
  FormInput,
  FormGroup,
  FormButton,
  FormTextarea,
  FormSelect,
} from "./Form";
import styles from "./ManageTaskForm.module.scss";

interface ManageTaskFormProps {
  taskData: {
    id: number;
    title: string;
    status: string;
    priority: string;
    assignee: string;
    taskType: string;
    taskResources: string;
    operations: string;
    taskInputMaterials: string;
    field: string;
    cropZone: string;
    description: string;
    start: string;
    end: string;
  };
  onClose: () => void;
  onSave: (updatedTask: any) => void;
  onDone: () => void;
  onCancel: () => void;
}

export default function ManageTaskForm({
  taskData,
  onClose,
  onSave,
  onDone,
  onCancel,
}: ManageTaskFormProps) {
  const [formValues, setFormValues] = useState(taskData);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formValues);
    onClose();
  };

   const handleDone = () => {
     onSave({ ...formValues, status: "Done" });
     onClose();
     onDone(); 
   };

   const handleCancel = () => {
     onSave({ ...formValues, status: "Cancel" });
     onClose();
     onCancel(); 
   };

  useEffect(() => {
    setFormValues(taskData);
  }, [taskData]);

  return (
    <>
      <Form customClassName={styles["custom-form"]}>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormInput
            id="task-name"
            name="title"
            label="Task Name *"
            isRequired={true}
            value={formValues.title}
            onChange={handleInputChange}
          />
          <FormSelect
            id="task-resources"
            name="taskResources"
            label="Task Resources"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            value={formValues.taskResources}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormGroup
            customCSS={{
              gridColumn: "span 2",
              margin: 0,
            }}
            customClassName={styles["status-group"]}
          >
            <FormSelect
              id="status"
              name="status"
              label="Status"
              selectOptions={["Open", "Done", "Cancel"]}
              value={formValues.status}
              onChange={handleInputChange}
              customCSS={{ width: "100%" }}
            />
            <button className={`${styles["done-button"]}`} onClick={handleDone}>Done</button>
            <button className={`${styles["cancel-button"]}`} onClick={handleCancel}>Cancel</button>
          </FormGroup>
          <FormSelect
            id="assignee"
            name="assignee"
            label="Assignee"
            selectOptions={["John Doe", "Jane Smith", "Other"]}
            value={formValues.assignee}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormSelect
            id="priority"
            name="priority"
            label="Priority"
            selectOptions={["very high","high", "medium", "low"]}
            value={formValues.priority || ""}
            onChange={handleInputChange}
          />
          <FormSelect
            id="taskType"
            name="taskType"
            label="Task Type *"
            selectOptions={["Type 1", "Type 2", "Type 3"]}
            value={formValues.taskType}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
          customClassName={styles["description-row"]}
        >
          <FormTextarea
            id="description"
            name="description"
            label="Description"
            value={formValues.description}
            onChange={handleInputChange}
          />
          <FormGroup>
            <FormSelect
              id="task-input-materials"
              name="taskInputMaterials"
              label="Input Materials"
              selectOptions={["Material 1", "Material 2", "Material 3"]}
              value={formValues.taskInputMaterials}
              onChange={handleInputChange}
            />
            <FormSelect
              id="task-operations"
              name="operations"
              label="Task Operations"
              selectOptions={["Operation 1", "Operation 2", "Operation 3"]}
              value={formValues.operations}
              onChange={handleInputChange}
            />
          </FormGroup>
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormSelect
            id="field"
            name="field"
            label="Field"
            selectOptions={["Field 1", "Field 2", "Field 3"]}
            value={formValues.field}
            onChange={handleInputChange}
          />
          <FormSelect
            id="cropZone"
            name="cropZone"
            label="Crop Zone"
            selectOptions={["Zone A", "Zone B", "Zone C"]}
            value={formValues.cropZone}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormInput
            id="start-date"
            name="start"
            type="date"
            label="Start Date"
            value={new Date(formValues.start).toISOString().split("T")[0]}
            onChange={handleInputChange}
          />
          <FormInput
            id="end-date"
            name="end"
            type="date"
            label="End Date"
            value={new Date(formValues.end).toISOString().split("T")[0]}
            onChange={handleInputChange}
          />
          <FormButton customCSS={{ marginTop: "1.5rem" }} action={handleSubmit}>
            Edit
          </FormButton>
        </FormGroup>
      </Form>
    </>
  );
}
