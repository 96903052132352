import React, { ReactNode } from "react";
import styles from "./FullScreenComponent.module.scss";
import closeIcon from "../../assets/icons/close.svg";

interface FullScreenModalProps {
  title: string;
  icon?: string;
  isOpen: boolean;
  isFullScreen?: boolean;
  onClose: () => void;
  children: ReactNode;
  isClosing?: boolean;
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({
  title,
  icon,
  isOpen,
  isFullScreen = false,
  onClose,
  children,
  isClosing = false,
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div
        className={`${styles["full-screen"]} ${
          isFullScreen ? styles.full : styles.partial
        } ${isClosing ? styles.closing : ""}`}
      >
        <div className={styles["full-screen__heading"]}>
          {icon && (
            <img
              src={icon}
              alt={`${title} Icon`}
              className={styles["full-screen__heading__icon"]}
            />
          )}
          <h2>{title}</h2>
          <button
            className={styles["full-screen__heading__close-btn"]}
            onClick={onClose}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
        <div className={styles["full-screen__content"]}>{children}</div>
      </div>
    </div>
  );
};

export default FullScreenModal;
