import React, { useState } from "react";
import styles from "../../styles/FarmSetupFields.module.scss";
import arrDown from "../../assets/icons/arrDown.svg";
import arrUp from "../../assets/icons/tasks/closeup.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import SingleFieldItems from "./SingleFieldItems";

interface Field {
  id: number;
  name: string;
  isChecked: boolean;
}

interface SingleFieldProps {
  field: Field;
  onChange: (id: number) => void;
}

const SingleField: React.FC<SingleFieldProps> = ({ field, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <>
      <input
        className={styles["single-field__checkbox"]}
        name="field"
        type="checkbox"
        checked={field.isChecked}
        onChange={() => onChange(field.id)}
      />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="4" />
        <path d="M16.6668 8.79163L10.2502 15.2083L7.3335 12.2916" />
      </svg>
      <div className={styles["single-field"]}>
        <div className={styles["form__input"]}>
          <span className={styles["single-field__accordion-heading"]}>
            <h2>{field.name}</h2>
            <div className={styles["single-field__heading-controls"]}>
              <button type="button" onClick={toggleAccordion}>
                <img
                  src={isOpen ? arrUp : arrDown}
                  className={styles["single-field__accordion-arrow"]}
                  alt={isOpen ? "Arr Up" : "Arr Down"}
                />
              </button>
              <img
                className={styles["single-field__delete"]}
                src={deleteIcon}
                alt="Delete"
              />
            </div>
          </span>
          <div
            className={`${styles["single-field__is-closed"]} ${
              isOpen ? styles["single-field__is-opened"] : ""
            }`}
          >
            {isOpen && <SingleFieldItems />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleField;
