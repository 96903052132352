import styles from "../../styles/TaskList.module.scss";
import locationIcon from "../../assets/icons/pin.svg";
import { useEffect, useState } from "react";


interface Task {
  id: string;
  name: string;
  area: string;
  time: string;
  status: "inProgress" | "completed" | "overdue" | "upcoming" | "canceled" | "drafted";
  duration: string;
  deadline: string;
}

export const initialTasks: Task[] = [
  {
    id: "1",
    name: "Crop Rotations",
    area: "Wheat F3",
    time: "10:30 AM",
    status: "inProgress",
    duration: "1hr",
    deadline: new Date(
      new Date().setDate(new Date().getDate() + 3)
    ).toISOString(),
  },
  {
    id: "2",
    name: "Irrigation",
    area: "Wheat F3",
    time: "10:30 AM",
    status: "completed",
    duration: "1hr",
    deadline: new Date(
      new Date().setDate(new Date().getDate() - 2)
    ).toISOString(),
  },
  {
    id: "3",
    name: "Fertilization",
    area: "Wheat F3",
    time: "10:30 AM",
    status: "overdue",
    duration: "30min",
    deadline: new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toISOString(),
  },
  {
    id: "4",
    name: "Harvesting",
    area: "Wheat F3",
    time: "10:30 AM",
    status: "upcoming",
    duration: "1hr",
    deadline: new Date(
      new Date().setDate(new Date().getDate() + 5)
    ).toISOString(),
  },
  {
    id: "5",
    name: "Soil Testing",
    area: "Wheat F3",
    time: "11:00 AM",
    status: "drafted",
    duration: "1hr",
    deadline: new Date(
      new Date().setDate(new Date().getDate() + 7)
    ).toISOString(),
  },
  {
    id: "6",
    name: "Pest Control",
    area: "Wheat F3",
    time: "12:00 PM",
    status: "canceled",
    duration: "45min",
    deadline: new Date(
      new Date().setDate(new Date().getDate() + 10)
    ).toISOString(),
  },
];

export function TaskList({
  isOverdue = false,
  showCheckbox = true,
  showDate = false,
  filter = [],
}: {
  isOverdue?: boolean;
  showCheckbox?: boolean;
  showDate?: boolean;
  filter?: string[];
}) {
  const [tasks, setTasks] = useState<Task[]>(initialTasks);

  const formatDate = (deadline: string) => {
    const date = new Date(deadline);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    return { month, day };
  };

  const checkOverdue = () => {
    const currentDate = new Date();
    setTasks((prevTasks) =>
      prevTasks.map((task) => {
        const taskDeadline = new Date(task.deadline);
        if (currentDate > taskDeadline && task.status !== "completed") {
          return {
            ...task,
            status: "overdue",
          };
        }
        return task;
      })
    );
  };

  useEffect(() => {
    const interval = setInterval(checkOverdue, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleCheckbox = (
    taskId: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          status: event.target.checked ? "completed" : "inProgress",
        } as Task;
      }
      return task;
    });
    setTasks(newTasks);
  };

  const filteredTasks = tasks.filter((task) => {
    const currentDate = new Date();
    const deadline = new Date(task.deadline);

    if (isOverdue) {
      return currentDate > deadline && task.status !== "completed";
    }

    if (filter.includes("All") || filter.length === 0) {
      return true;
    }

    if (filter.length > 0 && !filter.includes(task.status)) return false;
    return true;
  });

  return (
    <ul className={styles["task-list"]}>
      {filteredTasks.map((task) => {
        const { month, day } = formatDate(task.deadline);
        return (
          <li
            key={task.id}
            className={`${styles["task-list__item"]} ${
              styles[`task-list__item--${task.status}`]
            }`}
          >
            {showDate && (
              <div className={styles["task-list__date"]}>
                <p className={styles["task-list__date__month"]}>{month}</p>
                <p className={styles["task-list__date__day"]}>{day}</p>
              </div>
            )}
            <div
              className={`${styles["task-list__content"]} ${
                isOverdue && task.status === "overdue"
                  ? styles["task-list__content--overdue"]
                  : ""
              }`}
            >
              {showCheckbox && (
                <div className={styles["task-list__checkbox"]}>
                  <input
                    id={`task-${task.id}`}
                    name={`task-${task.id}`}
                    type="checkbox"
                    checked={task.status === "completed"}
                    onChange={(event) => handleCheckbox(task.id, event)}
                    aria-label={`Mark task ${task.name} as completed`}
                  />
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" rx="4" />
                    <path d="M16.6668 8.79163L10.2502 15.2083L7.3335 12.2916" />
                  </svg>
                </div>
              )}
              <div className={styles["task-list__heading"]}>
                <span className={styles["task-list__heading__title"]}>
                  {task.name}
                </span>
                {task.area && (
                  <span className={styles["task-list__heading__subtitle"]}>
                    <img src={locationIcon} alt="Location pin" />
                    {task.area}
                  </span>
                )}
              </div>
              <div
                className={`${styles["task-list__status"]} ${
                  styles[`task-list__status--${task.status}`]
                }`}
              ></div>
              <div>
                <div className={styles["task-list__info"]}>
                  <span>{task.time}</span>
                </div>
                <div className={styles["task-list__info"]}>
                  <span>{task.duration}</span>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
