import React, { ChangeEvent } from "react";
import {
  Form,
  FormCheckButton,
  FormGroup,
  FormSelect,
  FormCheckboxDropdown,
} from "../form/Form";

import calendarIcon from "../../assets/icons/calendar.svg";
import filterIcon from "../../assets/icons/filter.svg";
import menuIcon from "../../assets/icons/menu.svg";
import fieldIcon from "../../assets/icons/field.svg";
import taskIcon from "../../assets/icons/task.svg";
import resourceIcon from "../../assets/icons/resource.svg";

import styles from "./CalendarFilters.module.scss";

interface CustomCalendarProps {
  view: string;
  setView: (event: ChangeEvent<HTMLSelectElement>) => void;
  assigneeList: string[];
  setAssignee: (event: ChangeEvent<HTMLInputElement>) => void;
  statusList: string[];
  setStatus: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function CalendarFilters({
  view,
  setView,
  assigneeList,
  setAssignee,
  statusList,
  setStatus,
}: CustomCalendarProps) {
  return (
    <>
      <div className={styles["calendar-filters"]}>
        <Form customClassName={styles["calendar-filters__form"]}>
          <FormGroup
            isRow={true}
            customClassName={styles["calendar-filters__group"]}
          >
            <h3 className={styles["calendar-filters__view"]}>View</h3>
            <FormSelect
              id="calendar-form-view"
              name="calendar-form-view"
              label="Select view"
              selectOptions={["Month", "Week", "Day", "Agenda"]}
              value={view}
              onChange={setView}
              icon={calendarIcon}
              customClassName={styles["calendar-filters__select"]}
              customCSS={{ flex: "initial" }}
            />
            <FormCheckButton
              id="calendar-form-field"
              name="calendar-form-field"
              label="Field"
              icon={fieldIcon}
              customClassName={styles["calendar-filters__field"]}
              labelClassName={styles["calendar-filters__field-label"]}
              iconClassName={styles["calendar-filters__field-icon"]}
            />
            <FormCheckButton
              id="calendar-form-task"
              name="calendar-form-task"
              label="Task"
              icon={taskIcon}
              customClassName={styles["calendar-filters__field"]}
              labelClassName={styles["calendar-filters__field-label"]}
              iconClassName={styles["calendar-filters__field-icon"]}
            />
            <FormCheckButton
              id="calendar-form-resources"
              name="calendar-form-resources"
              label="Resources"
              icon={resourceIcon}
              customClassName={styles["calendar-filters__field"]}
              labelClassName={styles["calendar-filters__field-label"]}
              iconClassName={styles["calendar-filters__field-icon"]}
            />
          </FormGroup>
          <FormGroup
            isRow={true}
            customClassName={styles["calendar-filters__group"]}
          >
            <h3>
              <img src={filterIcon} alt="Filters" /> Filters
            </h3>
            <FormCheckboxDropdown
              name="Assignee"
              icon={menuIcon}
              onChange={setAssignee}
              selectOptions={assigneeList}
              customClassName={`${styles["calendar-filters__filtering"]}`}
              customCSS={{ width: "50%" }}
            />
            <FormCheckboxDropdown
              name="Status"
              icon={menuIcon}
              selectOptions={statusList}
              onChange={setStatus}
              customClassName={`${styles["calendar-filters__filtering"]}`}
              customCSS={{ width: "50%" }}
            />
          </FormGroup>
        </Form>
      </div>
    </>
  );
}
