import styles from "../../styles/FarmSetupFields.module.scss";
import SetupHeading from "./SetupHeading";
import SingleField from "./SingleField";
import type { SetupProps } from "../../types";
import { useEffect, useContext , useState} from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";

type TFarmInfoProps = SetupProps;

const FarmFields: React.FC<TFarmInfoProps> = ({ page, changePage }) => {
  const { setLocale } = useContext(LocaleContext);
  const [fields, setFields] = useState([
    { id: 1, name: "Auskeben Nord", isChecked: false },
  ]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setFields(fields.map(field => ({ ...field, isChecked: checked })));
  };

  const handleFieldChange = (id:number) => {
    const newFields = fields.map(field => {
      if (field.id === id) {
        return { ...field, isChecked: !field.isChecked };
      }
      return field;
    });
    setFields(newFields);
    setSelectAll(newFields.every(field => field.isChecked));
  };

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  return (
    <div className={styles["setup-fields"]}>
      <SetupHeading page={page} changePage={changePage} />
      <div className={styles["setup-fields__actions"]}>
        <label className={styles["setup-fields__selectAll"]}>
          <input
            type="checkbox"
            className={styles["setup-fields__selectAllCheckbox"]}
            onChange={handleSelectAll}
          />
          <span className={styles["setup-fields__selectAllText"]}>
            {selectAll?<>DESELECT ALL</>:<>SELECT ALL</>}
          </span>
        </label>
        <button
          onClick={() => changePage({ goToIndex: "3" })}
          type="button"
          className={styles["setup-fields__addFieldBtn"]}
        >
          <Trans id="farmFields.addButton">Add Field</Trans>
        </button>
      </div>
      <div className={styles["setup-fields__formContainer"]}>
      <form action="" className={styles["setup-fields__form"]}>
      {fields.map(field => (
          <SingleField key={field.id} field={field} onChange={() => handleFieldChange(field.id)} />
        ))}
      </form>
      </div>
      <button
        onClick={() => changePage({ goToIndex: "4" })}
        className={styles["setup-fields__importButton"]}
      >
        {" "}
        <Trans id="farmFields.importButton">Import Your Fields</Trans>
      </button>
    </div>
  );
};

export default FarmFields;
