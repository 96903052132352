import React from "react";
import agendaPencil from "../../assets/icons/agendaPencil.svg";
import copyIcon from "../../assets/icons/copy.svg";
import clockIcon from "../../assets/icons/clock.svg";

interface AgendaEventProps {
  id: number;
  title: string;
  start: Date;
  end: Date;
  priority?: string;
  assignee?: string;
  description?: string;
  onEdit?: (taskData: any) => void;
  onCopy?: (taskId: number) => void;
}

const AgendaEvent = ({
  id,
  title,
  start,
  end,
  priority,
  assignee,
  description,
  onEdit,
  onCopy,
}: AgendaEventProps) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const days = ["Sun", "Mon", "Tues", "Wed", "Thrus", "Fri", "Sat"];

  const isSameDay = start.toDateString() === end.toDateString();

  const handleEditClick = () => {
    if (onEdit) {
      onEdit({
        id,
        title,
        start,
        end,
        priority,
        assignee,
        location,
        status,
        description,
      });
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority.toLowerCase()) {
      case "very high":
        return "var(--priority-very-high)";
      case "high":
        return "var(--priority-high)";
      case "medium":
        return "var(--priority-medium)";
      case "normal":
        return "var(--priority-normal)";
      case "low":
        return "var(--priority-low)";
      default:
        return "var(--priority-default)";
    }
  };

  const duration = Math.abs(start.getTime() - end.getTime()) / (1000 * 60 * 60);

  return (
    <div className="agenda-event">
      <div className="agenda-event__time">
        <img src={clockIcon} alt="" />
        <span>
          {start.getHours().toString().padStart(2, "0")}:
          {start.getMinutes().toString().padStart(2, "0")}
        </span>
        <span>
          {duration} hr{duration > 1 ? "s" : ""}
        </span>
      </div>
      <div
        className="agenda-event__bar"
        style={{ backgroundColor: getPriorityColor(priority || "") }}
      ></div>
      <div className="agenda-event__content">
        <div className="agenda-event__content__header">
          <p className="agenda-event__content__header__date">
            {isSameDay
              ? `${days[start.getDay()]} ${start.getDate()} ${
                  months[start.getMonth()]
                }`
              : `${days[start.getDay()]} ${start.getDate()} ${
                  months[start.getMonth()]
                } - ${days[end.getDay()]} ${end.getDate()} ${
                  months[end.getMonth()]
                }`}
          </p>
          <h2 className="agenda-event__content__header__title">{title}</h2>
          <div className="agenda-event__content__header__details">
            {priority && (
              <p
                className="agenda-event__content__header__details__priority"
                style={{
                  backgroundColor: getPriorityColor(priority),
                }}
              >
                {priority}
              </p>
            )}
            {assignee && (
              <p className="agenda-event__content__header__details__assignee">
                {assignee.split(" ")[0].charAt(0)}{" "}
                {assignee.split(" ")[1]?.charAt(0)}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="agenda-event__actions">
        <button onClick={handleEditClick}>
          <img src={agendaPencil} alt="Edit" />
        </button>
        <button onClick={() => onCopy && onCopy(id)}>
          <img src={copyIcon} alt="Copy" />
        </button>
      </div>
    </div>
  );
};

export default AgendaEvent;
