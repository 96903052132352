import React, { useState } from "react";
import ProfileHeading from "./ProfileHeading";
import styles from "./userManager.module.scss";
import danger from "../../assets/icons/profile/danger.svg";
import dangers from "../../assets/icons/profile/danger2.svg";
import deleteIcon from "../../assets/icons/profile/delete.svg";
import useIsMobile from "../../hooks/useIsMobile";
import closeIcon from "../../assets/icons/close.svg";
import userManagement from "../../assets/icons/profile/userIcons.svg";
import plusIcon from "../../assets/icons/profile/plus.svg";
import { useNavigate } from "react-router-dom";

const useIsTablet = () => {
  return window.innerWidth >= 768 && window.innerWidth <= 1024;
};

interface User {
  name: string;
  role: string;
  hasTasks: boolean;
  icon: string;
}

interface UserManagementProps {
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  onClosed: () => void;
  onAddUserClick: () => void;
  onDeleteUser: (userName: string) => void;
}

const UserManagement: React.FC<UserManagementProps> = ({
  users,
  setUsers,
  onClosed,
  onAddUserClick,
  onDeleteUser,
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  const handleDeleteClick = (index: number) => {
    const user = users[index];
    if (!user.hasTasks) {
      const updatedUsers = users.filter((_, i) => i !== index);
      setUsers(updatedUsers);
      onDeleteUser(user.name);
    } else {
      setSelectedUser(index === selectedUser ? null : index);
    }
  };

  return (
    <>
      <div className={styles["user-management"]}>
        {isMobile ? (
          <div className={styles["user-management__header"]}>
            <img
              src={userManagement}
              alt="Profile Icon"
              className={styles["user-management__header__image"]}
            />
            <h3 className={styles["user-management__header__head"]}>
              User Management
            </h3>
            <img
              src={closeIcon}
              alt="Close"
              className={styles["user-management__close-btn"]}
              onClick={handleClose}
            />
          </div>
        ) : (
          <ProfileHeading
            pageTitle="User Management"
            prevPage="/profile"
            nextPage="/profile/profile-notifications"
          />
        )}

        <div className={styles["user-management__worker"]}>
          <div className={styles["user-management__worker__display"]}>
            <div
              className={styles["user-management__worker__display__heading"]}
            >
              <span>YOUR COWORKERS</span>
              {isMobile ? (
                <button
                  className={
                    styles[
                      "user-management__worker__display__heading__add-user-btn"
                    ]
                  }
                  onClick={onAddUserClick}
                >
                  <img src={plusIcon} alt="" /> Add User
                </button>
              ) : (
                ""
              )}
            </div>
            <ul>
              {users.map((user, index) => (
                <React.Fragment key={index}>
                  <li
                    className={styles["user-management__worker__display__user"]}
                  >
                    <div
                      className={
                        styles["user-management__worker__display__user-info"]
                      }
                    >
                      <h2>{user.name}</h2>{" "}
                      <span>{user.role.toUpperCase()}</span>
                    </div>
                    <img
                      src={
                        user.hasTasks && selectedUser === index
                          ? danger
                          : deleteIcon
                      }
                      alt="Delete Icon"
                      onClick={() => handleDeleteClick(index)}
                    />
                  </li>

                  {(isMobile || isTablet) &&
                    selectedUser === index &&
                    user.hasTasks && (
                      <div className={styles["user-management__worker__info"]}>
                        <img src={dangers} alt="Danger icon" />
                        <h2
                          className={styles["user-management__worker__heading"]}
                        >
                          This user has tasks assigned. Please reassign their
                          tasks before deleting.
                        </h2>
                      </div>
                    )}
                </React.Fragment>
              ))}
            </ul>
          </div>

          {!isMobile &&
            !isTablet &&
            selectedUser !== null &&
            users[selectedUser].hasTasks && (
              <div className={styles["user-management__worker__info"]}>
                <img src={dangers} alt="Danger icon" />
                <h2 className={styles["user-management__worker__heading"]}>
                  This user has tasks assigned. Please reassign their tasks
                  before deleting.
                </h2>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default UserManagement;
