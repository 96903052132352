import styles from "../../styles/FarmSingleFieldItem.module.scss";
import Area from "../../assets/area.png";

import { useContext, useEffect } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";

export default function SingleFieldItems() {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  return (
    <div className={styles["accordion-items"]}>
      <div className={styles["accordion-items__group"]}>
        <div className="form__input" style={{"marginTop":"1rem"}}>
          <label style={{"color":"rgba(13, 24, 20, 0.5)"}} className={styles["accordion-items__item"]}>FIELD NAME*</label>
          <input type="text" name="field-name" value="Ausleben-Nord" />
        </div>
        <div className="form__input">
          <label style={{"color":"rgba(13, 24, 20, 0.5)"}} className={styles["accordion-items__item"]}>AREA*</label>
          <input type="text" name="area" value="Ausleben Nord" disabled />
        </div>
      </div>
      <div className="form__input" style={{"marginTop":"1rem"}}>
        <label style={{"color":"rgba(13, 24, 20, 0.5)"}} className={styles["accordion-items__item"]}>DESCRIPTION*</label>
        <input type="text" name="description" value="231984 (NZ)" />
      </div>
      <div className={styles["accordion-items__coordinates"]}>
        <img src={Area} />
        <div>
          <div className="form__input" style={{"marginTop":"1rem"}}>
            <label style={{"color":"rgba(13, 24, 20, 0.5)"}} className={styles["accordion-items__item"]}>
              COORDINATES/POLYGON*
            </label>
            <input
              type="text"
              name="coordinates"
              value="[234242423425...]"
              disabled
            />
          </div>
          <span>
            <div className="form__input">
              <label style={{"color":"rgba(13, 24, 20, 0.5)"}} className={styles["accordion-items__item"]}>
                SIZE (HA)*
              </label>
              <input type="text" name="size" value="213" disabled />
            </div>
            <div className="form__input">
              <label style={{"color":"rgba(13, 24, 20, 0.5)"}} className={styles["accordion-items__item"]}>
                DOUBLE SIZE(HA)*
              </label>
              <input type="text" name="area-type" value="arable" disabled />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}
