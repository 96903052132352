import styles from "../../styles/FarmNewField.module.scss";
import type { SetupProps } from "../../types";
import Area from "../../assets/area.png";

import React, {
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  CSSProperties,
  useRef
} from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";
import { useNavigate } from "react-router-dom";
import alertIcon from "../../assets/icons/redDanger.svg";

import useIsMobile from "../../hooks/useIsMobile";

import {
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  FormSelect,
  FormButton,
} from "../form/Form";

interface TAddNewFieldProps extends SetupProps {
  closeModal: () => void;
  headingTitle?: string;
  headingSubtitle?: string;
  headingTitleStyle?: CSSProperties;
  headingSubtitleStyle?: CSSProperties;
  onSuccess?: (newFieldName:string) => void;
}

interface FormData {
  fieldName: string;
  area: string;
  description: string;
  validFrom: string;
  validTo: string;
  coordinates: string;
  size: string;
  areaType: string;
}

const AddNewField: React.FC<TAddNewFieldProps> = ({
  page,
  changePage,
  closeModal,
  headingTitle,
  headingSubtitle,
  headingTitleStyle,
  headingSubtitleStyle,
  onSuccess
}) => {
  const { setLocale } = useContext(LocaleContext);
  const [formData, setFormData] = useState<FormData>({
    fieldName: "",
    area: "",
    description: "",
    validFrom: "",
    validTo: "",
    coordinates: "",
    size: "",
    areaType: "",
  });

  const [formError, setFormError] = useState<string>("");
  const [invalidField, setInvalidField] = useState<string[]>([]);
  const fieldRef = useRef<{
    [key: string]: HTMLInputElement | HTMLSelectElement | null;
  }>({});

  const validateDates = (start: string, end: string) => {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);

    const startDate = new Date(start);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(end);
    endDate.setHours(0, 0, 0, 0);

    if (startDate < todayDate) {
      return "Valid From should be today or a future date.";
    }

    if (endDate < todayDate || endDate < startDate) {
      return "Valid To must be today or a future date and cannot be earlier than Valid From.";
    }

    return null;
  };

  const validateForm = () => {
    let isValid = true;
    let errorMessage = "";
    const invalidFieldsArray: string[] = [];

    Object.entries(formData).forEach(([key, value]) => {
      if (!value || value === "please select") {
        isValid = false;
        errorMessage = "Please populate the mandatory fields";
        invalidFieldsArray.push(key);
      }
    });

    const dateError = validateDates(formData.validFrom, formData.validTo);
    if (dateError) {
      errorMessage = dateError;
      isValid = false;
      invalidFieldsArray.push("validFrom", "validTo");
    }

    setFormError(errorMessage);
    setInvalidField(invalidFieldsArray);

    if (!isValid && invalidFieldsArray.length > 0) {
      const firstInvalidField = invalidFieldsArray[0];

      fieldRef.current[firstInvalidField]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }

    return isValid;
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev: FormData) => ({
      ...prev,
      [name]: value,
    }));

    setInvalidField((prev) => prev.filter((field) => field !== name));
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleAddField = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setFormError("");
    onSuccess?.(formData.fieldName);
  };

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  const isMobile = useIsMobile();

  const firstGroupStyle: React.CSSProperties = isMobile
    ? {
        display: "block",
        marginBottom: "1rem",
      }
    : {
        display: "flex",
        gap: "3rem",
        flexWrap: "wrap",
      };

  const secondGroupStyle: React.CSSProperties = isMobile
    ? {
        display: "block",
      }
    : {
        display: "flex",
        gap: "1rem",
        flexWrap: "wrap",
      };

  const thirdGroupStyle: React.CSSProperties = isMobile
    ? {
        display: "flex",
        gap: "1rem",
      }
    : {
        display: "flex",
        gap: "2rem",
        flexWrap: "wrap",
      };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "2rem",
        borderTopLeftRadius:"2rem",
        borderBottomLeftRadius:!isMobile?"2rem":"0",
        borderTopRightRadius:isMobile?"2rem":"0",
        border: "1px solid #e3e7eb",
        color: "#999999",
        height: "100vh",
        overflow: "auto",
      }}
    >
      {headingTitle && (
        <h2
          style={{
            textAlign: "center",
            fontSize: "2.2rem",
            marginBottom: "1rem",
            color:"black",
            ...headingTitleStyle,
          }}
        >
          Add <span style={{"color":"rgba(66, 118, 98, 1)"}}>New Field</span>
        </h2>
      )}
      {headingSubtitle && (
        <p
          style={{
            textAlign: "center",
            fontSize: "1.6rem",
            marginBottom: "2rem",
            ...headingSubtitleStyle,
          }}
        >
          {headingSubtitle}
        </p>
      )}
      {formError && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "1rem",
            marginBottom: "1rem",
            backgroundColor: "rgba(255, 240, 240, 1)",
            borderRadius: "8px",
            color: "var(--alert-clr)",
            fontWeight: 700,
            fontSize: "1.3rem",
            textAlign: "center",
            padding: "1rem",
          }}
        >
          <div>
            <img src={alertIcon} alt="Alert Icon" />
          </div>
          <div>{formError}</div>
        </div>
      )}

      <Form
        customCSS={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          padding: "1.5rem",
        }}
        onSubmit={handleAddField}
      >
        <FormGroup isRow={true} customCSS={firstGroupStyle}>
          <FormInput
            id="field-name"
            name="fieldName"
            label="FIELD NAME*"
            placeholder="Enter field name"
            value={formData.fieldName}
            onChange={handleChange}
            inputRef={(input) => (fieldRef.current.fieldName = input)}
            customClassName={
              invalidField.includes("fieldName") ? styles["input-error"] : ""
            }
            customCSS={{
              flex: "1 1 45%",
              marginBottom: isMobile ? "1rem" : 0,
            }}
          />
          <FormInput
            id="field-area"
            name="area"
            label="AREA*"
            placeholder="Enter area"
            value={formData.area}
            onChange={handleChange}
            inputRef={(input) => (fieldRef.current.area = input)}
            customClassName={
              invalidField.includes("area") ? styles["input-error"] : ""
            }
            customCSS={{
              flex: "1 1 45%",
              marginTop: isMobile ? "1.2rem" : 0,
            }}
          />
        </FormGroup>
        <FormGroup isRow={true} customCSS={secondGroupStyle}>
          <FormTextarea
            id="description"
            name="description"
            label="DESCRIPTION*"
            placeholder="Enter description"
            value={formData.description}
            onChange={handleChange}
            customClassName={
              invalidField.includes("description") ? styles["input-error"] : ""
            }
            customCSS={{
              marginBottom: isMobile ? "1rem" : "0",
              flex: isMobile ? "1 1 100%" : "2 1 45%",
              resize: "vertical",
              padding: "0.5rem",
              borderRadius: "0.5rem",
            }}
          />
          <div
            style={{
              display: isMobile ? "block" : "flex",
              gap: "1rem",
              flex: isMobile ? "1 1 100%" : "2 1 45%",
            }}
          >
            <FormInput
              id="valid-from"
              name="validFrom"
              type="date"
              label="Valid From*"
              value={formData.validFrom}
              onChange={handleChange}
              inputRef={(input) => (fieldRef.current.validFrom = input)}
              customClassName={
                invalidField.includes("validFrom") ? styles["input-error"] : ""
              }
              customCSS={{
                marginBottom: isMobile ? "1rem" : 0,
                padding: "0.5rem",
                borderRadius: "0.5rem",
                flex: isMobile ? "1 1 auto" : "1 1 45%",
              }}
            />
            <FormInput
              id="valid-to"
              name="validTo"
              type="date"
              label="Valid To*"
              value={formData.validTo}
              onChange={handleChange}
              inputRef={(input) => (fieldRef.current.validTo = input)}
              customClassName={
                invalidField.includes("validTo") ? styles["input-error"] : ""
              }
              customCSS={{
                padding: "0.5rem",
                borderRadius: "0.5rem",
                flex: isMobile ? "1 1 auto" : "1 1 45%",
              }}
            />
          </div>
        </FormGroup>

        {isMobile ? (
          <>
            <FormGroup isRow={false} customCSS={thirdGroupStyle}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Area}
                  alt="Area"
                  style={{
                    width: "100%",
                    maxWidth: "40rem",
                    borderRadius: "1rem",
                    objectFit: "cover",
                  }}
                />
              </div>
              <FormInput
                id="coordinates"
                name="coordinates"
                label="Coordinates/Polygon*"
                placeholder="[80.96632957690962, 26.777063336375846...]"
                value={formData.coordinates}
                onChange={handleChange}
                customCSS={{
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                  width: "100%",
                  minHeight: "8rem",
                  height: "auto",
                  boxSizing: "border-box",
                }}
                inputRef={(input) => (fieldRef.current.coordinates = input)}
                customClassName={
                  invalidField.includes("coordinates")
                    ? styles["input-error"]
                    : ""
                }
              />
            </FormGroup>

            <FormGroup
              isRow={false}
              customCSS={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
              }}
            >
              <FormInput
                id="size"
                name="size"
                label="SIZE (HA)*"
                placeholder="Enter size in hectares"
                value={formData.size}
                onChange={handleChange}
                customCSS={{
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                  width: "100%",
                  marginBottom: "1rem",
                }}
                inputRef={(input) => (fieldRef.current.size = input)}
                customClassName={
                  invalidField.includes("size") ? styles["input-error"] : ""
                }
              />
              <FormSelect
                id="area-type"
                name="areaType"
                label="AREA TYPE*"
                selectOptions={["Option 1", "Option 2", "Option 3"]}
                value={formData.areaType}
                onChange={handleChange}
                customCSS={{
                  width: "100%",
                  marginTop: "0.5rem",
                }}
                inputRef={(input) => (fieldRef.current.areaType = input)}
                selectClassName={
                  invalidField.includes("areaType") ? styles["input-error"] : ""
                }
              />
            </FormGroup>
          </>
        ) : (
          <FormGroup isRow={true} customCSS={thirdGroupStyle}>
            <div
              style={{
                flex: "1 1 40%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "0",
              }}
            >
              <img
                src={Area}
                alt="Area"
                style={{
                  width: "100%",
                  maxWidth: "40rem",
                  borderRadius: "1rem",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                flex: "1 1 55%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <FormInput
                id="coordinates"
                name="coordinates"
                label="Coordinates/Polygon*"
                placeholder="[80.96632957690962, 26.777063336375846...]"
                value={formData.coordinates}
                onChange={handleChange}
                customCSS={{
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                }}
                inputRef={(input) => (fieldRef.current.coordinates = input)}
                customClassName={
                  invalidField.includes("coordinates")
                    ? styles["input-error"]
                    : ""
                }
              />
              <FormGroup
                isRow={true}
                customCSS={{
                  display: "flex",
                  gap: "2rem",
                  flexDirection: "row",
                }}
              >
                <FormInput
                  id="size"
                  name="size"
                  label="SIZE (HA)*"
                  placeholder="Enter size in hectares"
                  value={formData.size}
                  onChange={handleChange}
                  customCSS={{
                    flex: "1 1 50%",
                    marginLeft: "1rem",
                    marginBottom: "0",
                  }}
                  inputRef={(input) => (fieldRef.current.size = input)}
                  customClassName={
                    invalidField.includes("size") ? styles["input-error"] : ""
                  }
                />
                <FormSelect
                  id="area-type"
                  name="areaType"
                  label="AREA TYPE*"
                  selectOptions={["Option 1", "Option 2", "Option 3"]}
                  value={formData.areaType}
                  onChange={handleChange}
                  customCSS={{ flex: "1 1 50%" }}
                  inputRef={(input) => (fieldRef.current.areaType = input)}
                  selectClassName={
                    invalidField.includes("areaType")
                      ? styles["input-error"]
                      : ""
                  }
                />
              </FormGroup>
            </div>
          </FormGroup>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "4rem",
            flexDirection: "row",
          }}
        >
          <FormButton
            type="button"
            action={handleCancel}
            isTransparent={true}
            customCSS={{ width: "100%", height: "100%", padding: "1.5rem" }}
          >
            <strong>Cancel</strong>
          </FormButton>
          <FormButton
            type="submit"
            customCSS={{ width: "100%", height: "100%", padding: "1.5rem" }}
          >
            Add Field
          </FormButton>
        </div>
      </Form>
    </div>
  );
};

export default AddNewField;
