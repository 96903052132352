import pencilIcon from "../../assets/icons/pencil.svg";
import copyIcon from "../../assets/icons/copy.svg";

interface WeeklyEventProps {
  title: string;
  start: Date;
  end: Date;
  priority?: string;
  assignee?: string;
  description?: string;
  onEdit?: (taskData: any) => void;
}

export default function WeeklyEvent({
  title,
  start,
  end,
  priority,
  assignee,
  description,
  onEdit,
}: WeeklyEventProps) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const days = ["Sun", "Mon", "Tues", "Wed", "Thrus", "Fri", "Sat"];

  const handleEditClick = () => {
    if (onEdit) {
      onEdit({
        title,
        start,
        end,
        priority,
        assignee,
        location,
        description,
      });
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority.toLowerCase()) {
      case "very high":
        return "var(--priority-very-high)";
      case "high":
        return "var(--priority-high)";
      case "medium":
        return "var(--priority-medium)";
      case "normal":
        return "var(--priority-normal)";
      case "low":
        return "var(--priority-low)";
      default:
        return "var(--priority-default)";
    }
  };

  return (
    <>
      <div className="rbc-weekly-event rbc-event-wrapper">
        <div
          className="rbc-weekly-event__content"
          style={{
            borderTop: `5px solid ${getPriorityColor(priority || "")}`,
          }}
        >
          <div className="rbc-weekly-event__content__inner">
            <div className="rbc-weekly-event__content__duration">
              {start.getHours()}:{start.getMinutes()}{" "}
              <span className="rbc-weekly-event__content__duration__devider">
                |
              </span>
              {Math.abs(start.getTime() - end.getTime()) / (1000 * 60 * 60)}hr
            </div>
            <div className="rbc-weekly-event__content__title">{title}</div>
            <div className="rbc-weekly-event__content__date">
              {days[start.getDay()]} {start.getDay()} {months[start.getMonth()]}{" "}
              - {days[end.getDay()]} {end.getDay()} {months[end.getMonth()]}
            </div>
            <div className="rbc-weekly-event__content__actions">
              <button
                className="rbc-weekly-event__content__actions__button"
                onClick={handleEditClick}
              >
                <img src={pencilIcon} alt="Edit task" />
              </button>
              <button className="rbc-weekly-event__content__actions__button">
                <img src={copyIcon} alt="Copy task" />
              </button>
            </div>
            <div className="rbc-weekly-event__content__info">
              <span
                className="rbc-weekly-event__content__info__priority"
                style={{
                  backgroundColor: getPriorityColor(priority || ""),
                }}
              >
                {priority}
              </span>
              {assignee && (
                <span className="rbc-weekly-event__content__info__assignee">
                  {assignee.split(" ")[0].charAt(0)}{" "}
                  {assignee.split(" ")[1].charAt(0)}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
